<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!-- v-select
              v-model="eventsFilter"
              :options="eventsOptions"
              class="audit-filter-select mr-1"
              placeholder="All Types"
              multiple
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="!useAdvanceSearch"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-form-checkbox
                v-if="false"
                v-model="useAdvanceSearch"
                class="d-inline-block"
              >
                Advance Search
              </b-form-checkbox>
            </div -->
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refNotificationListTable"
        :small="true"
        :striped="false"
        :bordered="false"
        :borderless="false"
        :hover="true"
        sticky-header="65vh"
        :items="fetchAudits"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="audits-table position-relative"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner />
            loading...
          </div>
        </template>

        <template #head(actions)>
          <feather-icon
            icon="MenuIcon"
            class="mx-auto"
          />
        </template>

        <template #cell(type)="data">
          {{ getType(data.item.type) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAudits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BTooltip, BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import { get } from 'lodash'
import { httpGetFile } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import useNotificationList from './useNotificationList'
import auditStoreModule from '../notificationStoreModule'

import 'vue-json-viewer/style.css'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BSpinner,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const AUDIT_STORE_MODULE_NAME = 'app-audit'

    // Register module
    if (!store.hasModule(AUDIT_STORE_MODULE_NAME)) store.registerModule(AUDIT_STORE_MODULE_NAME, auditStoreModule)

    onMounted(() => {
      let thElm
      let startOffset

      Array.prototype.forEach.call(
        document.querySelectorAll('table th'),
        th => {
          // eslint-disable-next-line no-param-reassign
          th.style.position = 'relative'

          const grip = document.createElement('div')
          grip.innerHTML = '&nbsp;'
          grip.style.top = '0'
          grip.style.right = '0'
          grip.style.bottom = '0'
          grip.style.width = '5px'
          grip.style.position = 'absolute'
          grip.style.cursor = 'col-resize'
          grip.addEventListener('mousedown', e => {
            thElm = th
            startOffset = th.offsetWidth - e.pageX
          })

          th.appendChild(grip)
        },
      )

      document.addEventListener('mousemove', e => {
        if (thElm) {
          thElm.style.width = `${startOffset + e.pageX}px`
        }
      })

      document.addEventListener('mouseup', () => {
        thElm = undefined
      })
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDIT_STORE_MODULE_NAME)) store.unregisterModule(AUDIT_STORE_MODULE_NAME)
    })

    const {
      getFetchPayload,
      fetchAudits,
      eventsOptions,
      tableColumns,
      perPage,
      currentPage,
      totalAudits,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refNotificationListTable,

      eventsFilter,
      useAdvanceSearch,

      refetchData,

      resolveAuditEventVariantAndIcon,
      resolveClientAvatarVariant,
    } = useNotificationList()

    const onSearch = filter => {
      searchAdvance.value = filter
      refetchData()
    }

    const formatData = (data, emptyValue = null) => data ?? `<b-badge variant="light-secondary">${emptyValue ?? 'null'}</b-badge>`

    const getType = type => {
      const typeArray = type.split('\\')
      return typeArray[typeArray.length - 1]
    }

    return {
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalAudits,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refNotificationListTable,

      eventsFilter,
      useAdvanceSearch,

      refetchData,

      eventsOptions,

      avatarText,
      resolveAuditEventVariantAndIcon,
      resolveClientAvatarVariant,

      onSearch,
      formatData,
      get,
      getType,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.audits-table {
  ::v-deep td {
    font-size: 0.85rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  ::v-deep th {
    font-size: 0.9rem;
  }
}

.audit-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    min-width: 70px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.json-text {
  font-family: Consolas, Menlo, Courier, monospace;
  font-stretch: condensed;
}
</style>
